import {Button, Card, Cascader, Col, Form, Input, message, Row, Select} from "antd";
import {
    createProviderRequest,
    getNextOfficeProviderNumberRequest,
    getOrganizationOfficesRequest,
    getOrganizationsRequest, nextServiceCodeRequest,
    servicesQuerySetRequest
} from "../../utils/API";
import {useEffect, useRef, useState} from "react";
import {Option} from "antd/es/mentions";
import {useNavigate} from "react-router-dom";
import Authenticator from "../Authentications/Authenticator";
import {loadOrganizations, loadRegions} from "../../utils/commonFunctions";
import {PROVIDER_TYPES} from "../../utils/constants";


const CreateProvider = () => {
    const [loading, setLoading] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const [regions, setRegions] = useState([])
    const [organization, setOrganization] = useState()
    const [districts, setDistricts] = useState([])
    const [office, setOffice] = useState()
    const [office_options, setOfficeOptions] = useState([])
    const [service, setService] = useState(null)
    const [provider_type, setProviderType] = useState("Counter")
    const [provider_no, setProviderNo] = useState(null)
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log("Success:", values);
        setLoading(true)
        createProviderRequest(values, office)
            .then((response) => {
                console.log(response)

                if (response.status){
                    message.success("Provider " + values.provider_no + " was successfully created", 7).then()
                    navigate('/providers', {replace: true})
                }
                else {
                    message.warning('Data Provided is not correct, check and try again!', 7).then()
                }
                setLoading(false)

            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                message.error("Error occurred").then()
            })
    };
    const loadOfficeOptions = () => {
        getOrganizationsRequest().then((response) => {
            console.log(response)
            let re = response.results.map(async (data) => {
                console.log(data)
                let query_offices = await getOrganizationOfficesRequest(data.id)
                let offices = query_offices.map((office) => {
                    return {
                        value: office.id,
                        label: office.name,
                    }
                })
                return {
                    value: data.id,
                    label: data.name,
                    children: offices
                }
            })
            Promise.all(re).then((data) => {
                let cleaned_data = []
                data.forEach((i, idx) => {
                    if (i.children.length > 0) {
                        cleaned_data.push(i)
                    }
                })
                setOfficeOptions(cleaned_data)
            })
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleSearchOrganizations = (newValue) => {
        if (newValue) {
            loadOrganizations(newValue, setOrganizations);
        } else {
            setOrganizations([]);
        }
    };

    const onSelectOffice = (e) => {
        console.log('Selected', e)
        setOffice(e[e.length - 1])
        nextServiceCodeRequest(e[e.length - 1]).then((res) => form.setFieldsValue({
            code: res
        }))
    }

    const getProviderNo = (office, type) =>  {
        getNextOfficeProviderNumberRequest(office, type).then((res) => {
            form.setFieldsValue({
                provider_no: type + " " + res
            })
        })
    }
    const onSelectService = (e) => {
        console.log('Selected', e)
        setService(e[e.length - 1])
        getProviderNo(e[e.length - 1], provider_type)
    }
    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)

    useEffect(() => {
        // getDashboardData().then();
        Authenticator(navigate)
        loadOfficeOptions()
        loadRegions(setRegions)
        form.setFieldsValue({
            provider_type: "Counter"
        })
    }, [])

    return (
        <Card>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                form={form}
            >
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Office Path"
                            name="office"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select the Office Path!",
                                },
                            ]}
                        >
                            <Cascader
                                options={office_options}
                                onChange={onSelectOffice}
                                placeholder="Select Office Path"
                                showSearch={{
                                    filter,
                                }}
                                onSearch={(value) => console.log(value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Provider Unique Name"
                            name="provider_no"
                            rules={[
                                {
                                    required: true,
                                    message: "Provider unique name failed to be generated!",
                                },
                            ]}
                            initialValue={provider_no && provider_type + " " + provider_no}
                        >
                            <Input disabled  placeholder="..." type="text"/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Provider Type"
                            name="provider_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Provider Type!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: "100%",
                                    borderRadius: "20px"
                                }}
                                defaultValue="Counter"
                                onChange={(e) => {
                                    setProviderType(e)
                                        if (office !== null) {
                                            getProviderNo(office, e)
                                        }
                                }}
                                size={"large"}
                                placeholder="Select Provider Type"

                            >
                                {Object.keys(PROVIDER_TYPES).map(method =>
                                    <Option value={PROVIDER_TYPES[method]} key={method}>{method}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Form.Item label=" " colon={false}>
                        <Button type="primary" loading={loading} htmlType="submit" style={{ width: "200px" }}>
                            Submit Data
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    )
}

export default CreateProvider