/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];
  const organization = [
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16" height="16"
        fill="currentColor"
        className="bi bi-building"
        key={0}
        viewBox="0 0 16 16"
    >
      <path fill-rule="evenodd"
            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
      <path
          d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
    </svg>,
  ]

  const office = [
    <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="16" height="16" viewBox="0 0 100 100" enable-background="new 0 0 100 100">
      <path d="M80,83.333V10H20v73.333h-6.667V90h23.334V76.667h26.666V90h23.334v-6.667H80z M40,66.667h-6.667V60H40V66.667z M40,53.333
          h-6.667v-6.666H40V53.333z M40,40h-6.667v-6.667H40V40z M40,26.667h-6.667V20H40V26.667z M53.333,66.667h-6.666V60h6.666V66.667z
           M53.333,53.333h-6.666v-6.666h6.666V53.333z M53.333,40h-6.666v-6.667h6.666V40z M53.333,26.667h-6.666V20h6.666V26.667z
           M66.667,66.667H60V60h6.667V66.667z M66.667,53.333H60v-6.666h6.667V53.333z M66.667,40H60v-6.667h6.667V40z M66.667,26.667H60V20
          h6.667V26.667z"/>
    </svg>

  ]

  const service = [
    <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg"
         y="0px"
         viewBox="0 0 32 32">
      <path className="st0" d="M28,20.6V25c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2v-4.4"/>
      <path className="st0" d="M16,24L16,24c-1.1,0-2-0.9-2-2v-3h4v3C18,23.1,17.1,24,16,24z"/>
      <path className="st0" d="M14,22H7c-2.2,0-4-1.8-4-4v-8c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2v8c0,2.2-1.8,4-4,4h-7"/>
      <path className="st0" d="M20,8h-8V6c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V8z"/>
      <line className="st0" x1="7" y1="28" x2="7" y2="27"/>
      <line className="st0" x1="25" y1="28" x2="25" y2="27"/>
    </svg>
  ]
  const provider = [
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 376.6737 304.8316" enable-background="new 0 0 376.6737 304.8316" xml="preserve">
<g>
	<path d="M157.7263,181.8947h-3c-0.2548-8.7789-4.895-16.7415-12.3649-21.2792c-1.5363,2.1671-3.0506,4.9255-4.4244,8.4448
		c-1.4383,3.6839-2.6443,7.9698-3.6229,12.8344h-31.1108c0.0751-2.5807,0.5301-5.0665,1.3129-7.4051
		c-0.5954-0.0371-1.1876-0.1153-1.7639-0.2723c-0.4039-0.11-0.7921-0.2535-1.1699-0.4158
		c-0.8297,2.5607-1.3057,5.2771-1.3791,8.0931H85.9501c0.0883,1.2156,0.204,2.4043,0.3256,3.6097
		c0.2666,2.6362,0.541,5.3608,0.4893,8.5718l0.152,1.4606h6.1988H235.6h141.0737v-13.6421h-9.7613
		c-0.4044-15.5217-13.1475-28.0262-28.765-28.0262s-28.3605,12.5046-28.765,28.0262h-79.7404V13.6421h147.0316V0H0v13.6421h224.0842
		v168.2526h-10.7618c-0.9785-4.8645-2.1844-9.1505-3.6227-12.8344c-4.9389-12.6505-11.686-15.5111-14.7676-16.1475
		c-0.5775-0.1378-1.1771-0.2185-1.7969-0.2185h-38.6348c-0.6213,0-1.2223,0.0812-1.8011,0.2196
		c-1.8769,0.3886-5.107,1.6024-8.4625,5.3422C152.4122,163.368,157.4739,172.1855,157.7263,181.8947z M338.1475,156.8685
		c13.9631,0,25.3616,11.159,25.765,25.0262h-51.5299C312.7859,168.0275,324.1843,156.8685,338.1475,156.8685z"/>
  <circle cx="173.8181" cy="124.1748" r="22.5"/>
  <path d="M128.9683,156.8685c1.9976,0,3.9878,0.229,5.915,0.6812c2.0645,0.4844,4.0664,1.2261,5.9502,2.2046l1.3828-2.6621
		c-2.1045-1.0933-4.3408-1.9219-6.6479-2.4634c-1.3228-0.3101-2.673-0.5144-4.0333-0.6349l-3.258,2.8922
		C128.5074,156.8799,128.7365,156.8685,128.9683,156.8685z"/>
  <path d="M29.428,191.2007v-0.1411l0.0352-0.1406c0.5947-4.9429,0.791-5.8369,0.9707-6.251
		c0.3607-0.9976,0.751-1.9086,1.1564-2.7733H0v13.6421h28.8925L29.428,191.2007z"/>
  <path d="M154.6056,40.7415h-11.5723c-9.8467,0-17.8281,7.9819-17.8281,17.8281v11.5723c0,9.8135,7.9297,17.77,17.7305,17.8232
		L139.3712,103.2l8.5713-15.23h6.6631c9.8467,0,17.8281-7.9819,17.8281-17.8281V58.5696
		C172.4337,48.7234,164.4523,40.7415,154.6056,40.7415z M148.8195,79.6957c-8.472,0-15.34-6.868-15.34-15.34
		s6.868-15.34,15.34-15.34s15.34,6.8679,15.34,15.34S157.2915,79.6957,148.8195,79.6957z"/>
  <path d="M140.9681,59.2526l-1.9586,10.2062h1.5457l0.413-2.2188h2.1727l0.429,2.2188h1.6217l-2.066-10.2062H140.9681z
		 M141.2129,65.7864l0.8265-4.5752l0.8419,4.5752H141.2129z"/>
  <path d="M148.8958,59.2526h-2.6163v10.2062h1.5757v-4.1315h1.0406c1.0253,0,1.6678-0.1988,2.1421-0.6579
		c0.551-0.5202,0.8112-1.2853,0.8112-2.3871c0-1.0864-0.2602-1.8515-0.8112-2.3717
		C150.5636,59.4515,149.9211,59.2526,148.8958,59.2526z M148.5443,63.8737h-0.6891v-3.1674h0.6891
		c1.2695,0,1.6678,0.3826,1.6678,1.576C150.212,63.4911,149.8138,63.8737,148.5443,63.8737z"/>
  <path d="M155.6762,59.2526h-2.6164v10.2062h1.5757v-4.1315h1.0407c1.0253,0,1.6677-0.1988,2.142-0.6579
		c0.551-0.5202,0.8112-1.2853,0.8112-2.3871c0-1.0864-0.2602-1.8515-0.8112-2.3717
		C157.3439,59.4515,156.7015,59.2526,155.6762,59.2526z M155.3246,63.8737h-0.6891v-3.1674h0.6891
		c1.2695,0,1.6678,0.3826,1.6678,1.576C156.9924,63.4911,156.5941,63.8737,155.3246,63.8737z"/>
  <path d="M123.4859,133.4308l4.7875-21.4728l12.6071,2.8109l-3.4393,15.4259c0.5569,0.1304,1.1004,0.3087,1.6196,0.543
		l4.158-18.6494c0.2394-1.0736-0.4432-2.1479-1.5168-2.3873l-12.0195-2.6799c-1.0737-0.2394-2.1479,0.4432-2.3873,1.5168
		l-6.1477,27.573c-0.2288,1.0261,0.3892,2.0444,1.3811,2.3422l4.7142-4.1849L123.4859,133.4308z"/>
  <path d="M31.7298,191.3306c0,0.0039-0.002,0.0078-0.002,0.0117L19.096,293.6046c-0.6768,5.4814,3.2178,10.4736,8.6992,11.1509
		c0.416,0.0513,0.8291,0.0762,1.2383,0.0762c4.9756,0,9.2861-3.7104,9.9121-8.7749l10.3193-83.5386
		c4.749-3.6509,8.6113-4.5693,10.1982-4.2231c1.8203,0.3965,4.9355,2.9897,7.3574,9.5635l8.1211,78.0078
		c0.5352,5.1411,4.876,8.9653,9.9336,8.9653c0.3467,0,0.6963-0.0181,1.0479-0.0547c5.4932-0.5718,9.4834-5.4888,8.9111-10.9819
		l-10.3711-99.6196c0.1484-7.7773-1.7197-12.5894-0.5986-21.7554c0.7568-6.1885,5.2217-14.5674,9.5713-21.4966l5.4434,16.8057
		c0.6758,2.0874,2.3594,3.6924,4.4766,4.269c0.5635,0.1533,1.1367,0.228,1.707,0.228c1.5703,0,3.1113-0.5703,4.3154-1.6392
		l30.3789-26.9683c2.6846-2.3833,2.9287-6.4912,0.5459-9.1763c-2.3857-2.686-6.4922-2.9287-9.1768-0.5454l-22.9434,20.3677
		l-4.5225-13.9624c-0.4639-1.4316-1.3809-2.5879-2.5381-3.3716c-3.0166-2.7671-11.793-9.6812-27.2002-13.0391
		c-6.0048-1.3089-11.3069-1.7009-15.7867-1.6251c-2.2349,6.6719-4.8237,14.1154-10.922,17.7059
		c-4.511,2.6562-8.4264,3.2595-11.5169,3.0152l-3.9472,19.153c-0.5039,2.4473,0.4385,4.9673,2.4268,6.4819
		c1.1543,0.8799,2.543,1.3301,3.9404,1.3301c1.0078,0,2.0195-0.2339,2.9521-0.71l1.8369-0.937
		c-3.2148,6.5229-7.6035,9.5791-10.3477,17.252C32.4534,185.7579,32.136,187.9493,31.7298,191.3306z"/>
  <path d="M46.0471,137.9903c7.3808-4.3452,9.1698-15.8304,12.3289-23.1443c0.0588-0.136,0.1186-0.269,0.1783-0.402
		c0.4495-1.0011,0.9288-1.9155,1.4561-2.7139c0.9828,3.1821,2.689,6.0959,4.9746,8.501c3.1115,0.2686,6.2612,0.7235,9.4274,1.4136
		c5.0059,1.091,9.3362,2.551,13.0287,4.1298c6.9095-2.3425,12.4558-8.1429,14.1227-15.7906
		c2.5287-11.6017-4.8265-23.0568-16.4282-25.5854C74.0362,81.9791,63.077,88.6094,59.9341,99.347
		c4.9042-0.6755,9.5768,0.3079,12.6041,0.9456c0.3192,0.0673,0.1973,1.0545-0.1068,2.3276
		c-7.3571-1.5429-23.0886-5.2607-28.8973,17.3684c-4.1699,16.2447-10.3359,17.2745-14.649,15.8491
		C24.5718,134.4122,32.892,145.735,46.0471,137.9903z"/>

  <rect x="283.9944" y="84.9525" transform="matrix(0.7071 -0.7071 0.7071 0.7071 28.0902 240.2283)" width="40.0638" height="2.5076"/>

  <rect x="291.885" y="92.8431" transform="matrix(0.7071 -0.7071 0.7071 0.7071 24.8218 248.1189)" width="40.0638" height="2.5076"/>

  <rect x="266.4161" y="88.8978" transform="matrix(0.7071 -0.7071 0.7071 0.7071 26.456 244.1736)" width="83.1111" height="2.5077"/>

  <rect x="44.7523" y="53.4999" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.7419 61.8463)" width="40.0638" height="2.5077"/>

  <rect x="52.6429" y="61.3905" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -23.0103 69.7369)" width="40.0638" height="2.5077"/>
  <rect x="27.174" y="57.4452" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.376 65.7916)" width="83.1111" height="2.5076"/>
</g>
</svg>
]
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Foleni QMS - Admin</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/organizations">
            <span
              className="icon"
              style={{
                background: page === "organizations" ? color : "",
              }}
            >
              {organization}
            </span>
            <span className="label">Organizations</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/offices">
            <span
                className="icon"
                style={{
                  background: page === "offices" ? color : "",
                }}
            >
              {office}
            </span>
            <span className="label">Offices</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/services">
            <span
                className="icon"
                style={{
                  background: page === "services" ? color : "",
                }}
            >
              {organization}
            </span>
            <span className="label">Services</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/providers">
            <span
                className="icon"
                style={{
                  background: page === "providers" ? color : "",
                }}
            >
              {provider}
            </span>
            <span className="label">Providers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          Users Management
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/users">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Users</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          System Management
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/profile">
            <span
                className="icon"
                style={{
                  background: page === "profile" ? color : "",
                }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>

      </Menu>

    </>
  );
}

export default Sidenav;
