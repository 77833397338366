export const Config = {
    // apiURL: "http://127.0.0.1:7700/",
    // streamURL: "http://127.0.0.1:7700/",
    // socketURL: "ws://127.0.0.1:7700/",
    // mediaURL: "http://127.0.0.1:5000/streamer/v1/",
    // adsServiceURL: "http://127.0.0.1:5000/"
    mediaURL: "https://media.foleniqms.com/v1/",
    apiURL: 'https://api.foleniqms.com/',
    streamURL: 'https://media.foleniqms.com/',
    adsServiceURL: "https://ads.foleniqms.com/",
    socketURL: "wss://socket.foleniqms.com/"
}
