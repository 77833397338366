import {Button, Card, Cascader, Col, Form, Input, message, Row} from "antd";
import {
    createOperator,
    getOrganizationOfficesRequest, getOrganizationsRequest,
} from "../../utils/API";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Authenticator from "../Authentications/Authenticator";
import {loadOrganizations, loadRegions} from "../../utils/commonFunctions";


const CreateUser = () => {
    const [loading, setLoading] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const [regions, setRegions] = useState([])
    const [organization, setOrganization] = useState()
    const [districts, setDistricts] = useState([])
    const [office_options, setOfficeOptions] = useState([])
    const [office, setOffice] = useState(null)
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log("Success:", values);
        createOperator(values, office)
            .then((response) => {
                console.log(response)
                if (response){
                    message.success( values.username + " was successfully created").then()
                    navigate('/services', {replace: true})
                }
                else {
                    message.warning('Data Provided is not correct, check and try again!', 7).then()
                }

            })
            .catch((err) => {
                console.log('Error', err)
                message.error("Error occurred").then()
            })
    };
    const loadOfficeOptions = () => {
        getOrganizationsRequest().then((response) => {
            console.log(response)
            let re = response.results.map(async (data) => {
                console.log(data)
                let query_offices = await getOrganizationOfficesRequest(data.id)
                let offices = query_offices.map((office) => {
                    return {
                        value: office.id,
                        label: office.name,
                    }
                })
                return {
                    value: data.id,
                    label: data.name,
                    children: offices
                }
            })
            Promise.all(re).then((data) => {
                let cleaned_data = []
                data.forEach((i, idx) => {
                    if (i.children.length > 0) {
                        cleaned_data.push(i)
                    }
                })
                setOfficeOptions(cleaned_data)
            })
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleSearchOrganizations = (newValue) => {
        if (newValue) {
            loadOrganizations(newValue, setOrganizations);
        } else {
            setOrganizations([]);
        }
    };
    const onSelectOffice = (e) => {
        console.log('Selected', e)
        setOffice(e[e.length - 1])
    }
    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)

    useEffect(() => {
        // getDashboardData().then();
        Authenticator(navigate)
        loadOfficeOptions()
        loadRegions(setRegions)
    }, [])

    return (
        <Card>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                form={form}
            >
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Office Path"
                            name="office"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select the Office Path!",
                                },
                            ]}
                        >
                            <Cascader
                                options={office_options}
                                onChange={onSelectOffice}
                                placeholder="Select Office Path"
                                showSearch={{
                                    filter,
                                }}
                                onSearch={(value) => console.log(value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Full Name"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please provide Valid Name!",
                                },
                            ]}
                        >
                            <Input  placeholder="eg. John Carter" type="text"/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Provide Valid email!",
                                },
                            ]}
                        >
                            <Input placeholder="eg. example@example.com" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>

                        <Form.Item
                            className="username"
                            label="Cell Phone"
                            name="phone_number"
                            rules={[
                                {
                                    required: true,
                                    message: "Invalid Cell Phone!",
                                },
                            ]}
                        >
                            <Input placeholder="eg. 0719******" />
                        </Form.Item>

                    </Col>
                </Row>
                <Row justify="end">
                    <Form.Item label=" " colon={false}>
                        <Button type="primary" loading={loading} htmlType="submit" style={{ width: "200px" }}>
                            Submit Data
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    )
}

export default CreateUser