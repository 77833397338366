import {Avatar, Badge, Button, Card, Col, Descriptions, List, Row, Slider, Switch} from "antd";
import {pencil} from "../../../utils/svgs";
import {CheckOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined} from "@ant-design/icons";
import convesionImg from "../../../assets/images/face-3.jpg";
import convesionImg2 from "../../../assets/images/face-4.jpg";
import convesionImg3 from "../../../assets/images/face-5.jpeg";
import convesionImg4 from "../../../assets/images/face-6.jpeg";
import convesionImg5 from "../../../assets/images/face-2.jpg";
import {useState} from "react";
import PricingCard from "./pricingCard";

const gutters = {};
const vgutters = {};
const colCounts = {};

[8, 16, 24, 32, 40, 48].forEach((value, i) => {
    gutters[i] = value;
});
[8, 16, 24, 32, 40, 48].forEach((value, i) => {
    vgutters[i] = value;
});
[2, 3, 4, 6, 8, 12].forEach((value, i) => {
    colCounts[i] = value;
});
const Pricing = () => {
    const [gutterKey, setGutterKey] = useState(1);
    const [offices_no, setOfficesNo] = useState(1);
    const [providers_no, setProvidersNo] = useState(1);

    const data = [
        {
            title: "Sophie B.",
            avatar: convesionImg,
            description: "Hi! I need more information…",
        },
        {
            title: "Anne Marie",
            avatar: convesionImg2,
            description: "Awesome work, can you…",
        },
        {
            title: "Ivan",
            avatar: convesionImg3,
            description: "About files I can…",
        },
        {
            title: "Peterson",
            avatar: convesionImg4,
            description: "Have a great afternoon…",
        },
        {
            title: "Nick Daniel",
            avatar: convesionImg5,
            description: "Hi! I need more information…",
        },
    ];

    return (
        <>
            <span>Number of Offices</span>
            <Row>
                <div
                    style={{
                        width: '50%',
                        paddingLeft: '3%'
                    }}
                >
                    <Slider
                        min={0}
                        max={Object.keys(gutters).length - 1}
                        value={offices_no}
                        onChange={setOfficesNo}
                        marks={gutters}
                        step={null}
                        tooltip={{
                            formatter: (value) => `${gutters[value]} Offices`,
                        }}
                    />
                </div>
            </Row>
            <span>Number of Providers</span>
            <Row>
                <div
                    style={{
                        width: '50%',
                        paddingLeft: '3%'
                    }}
                >
                    <Slider
                        min={0}
                        max={Object.keys(gutters).length - 1}
                        value={gutterKey}
                        onChange={setGutterKey}
                        marks={gutters}
                        step={null}
                        tooltip={{
                            formatter: (value) => `${gutters[value]} providers / office`,
                        }}
                    />
                </div>
            </Row>
            <Row gutter={[32, 16]} className={"mt-25"} justify="center" align="middle">
                <Col span={6} md={8} sm={12} className="mb-24" style={{maxWidth: '300px', minHeight: '750'}}>
                    <PricingCard />
                </Col>

                <Col span={6} md={8} sm={12} className="mb-24" style={{maxWidth: '300px', minHeight: '750'}}>
                    <Badge.Ribbon text="Recommended" color="#055ffc">
                        <PricingCard />
                    </Badge.Ribbon>

                </Col>
                <Col span={6} md={8} sm={12} className="mb-24" style={{maxWidth: '300px', minHeight: '750'}}>
                    <PricingCard />

                </Col>





            </Row>
        </>

    )
}

export default Pricing;
