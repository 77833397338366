import {Card, Col, Row} from "antd";
import {dollor, heart, profile} from "../../../utils/svgs";
import Title from "antd/es/typography/Title";
import {useEffect, useState} from "react";
import {openOfficeStatsWebSocket} from "../managers/sockets";

const OfficeOverview = ({office_id}) => {
    const [data, setData] = useState({
        waiting_customers: 0,
        today_visits: 0,
        total_visits: 0,
    })
    const count = [
        {
            today: "Waiting Customers",
            title: (data.waiting_customers).toLocaleString(),
            persent: "",
            icon: dollor,
            bnb: "bnb2",
        },
        {
            today: "Today's Visits",
            title: (data.today_visits).toLocaleString(),
            persent: "",
            icon: dollor,
            bnb: "bnb2",
        },
        {
            today: "Total Visits",
            title: (data.total_visits).toLocaleString(),
            persent: "",
            icon: profile,
            bnb: "bnb2",
        },
        {
            today: "Average waiting time",
            title: "2 mins",
            persent: "",
            icon: heart,
            bnb: "redtext",
        },
    ];
    const openOfficeStatsSocket = () => {
        openOfficeStatsWebSocket(office_id, setData)
    }

    useEffect(() => {
        // getOrganizationStats()
        openOfficeStatsSocket()
    }, [])

    return (
        <>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {count.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                        className="mb-24"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18} style={{padding: 20}}>
                                        <span>{c.today}</span>
                                        <Title level={3}>
                                            {c.title} <small className={c.bnb}>{c.persent}</small>
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box">{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default OfficeOverview
