import {Button, Col, Collapse, Form, Input, Row, Select, Tag, theme} from "antd";
import { TweenOneGroup } from 'rc-tween-one';
import {useEffect, useRef, useState} from "react";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Authenticator from "../../Authentications/Authenticator";
import {
    createProviderServiceRequest,
    deleteProviderServiceRequest,
    getProviderServicesRequest,
    servicesQuerySetRequest
} from "../../../utils/API";

const { Panel } = Collapse;


const ProviderConfig = ({provider}) => {
    const { token } = theme.useToken();
    const [inputVisible, setInputVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [provider_services, setProviderServices] = useState([])
    const [inputValue, setInputValue] = useState()
    const [all_services, setAllServices] = useState([])
    const inputRef = useRef()

    const tagPlusStyle = {
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };

    const handleInputConfirm = () => {
        // if (inputValue && tags.indexOf(inputValue) === -1) {
        //     setTags([...tags, inputValue]);
        // }
        setInputVisible(false);
        setInputValue('');
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleClose = (removedProviderService) => {
        deleteProviderServiceRequest(removedProviderService.id)
            .then((res) => {
                console.log(res)
                const newProviderServices = provider_services.filter((provider_service) =>
                    provider_service.id !== removedProviderService.id
                );
                setProviderServices(newProviderServices);
            })
            .catch((err) => {
                console.log(err)
            })

    };

    const forMap = (provider_service, idx) => {
        const tagElem = (
            <Tag
                key={provider_service.id}
                closable
                onClose={(e) => {
                    e.preventDefault();
                    handleClose(provider_service);
                }}
            >
                {provider_service.service.en_name} {provider_service.service.sw_name !== "Null" && " / " + provider_service.service.sw_name}
            </Tag>
        );
        return (
            <span
                key={idx}
                style={{
                    display: 'inline-block',
                }}
            >
        {tagElem}
      </span>
        );
    };
    const serviceChild = provider_services.map(forMap);


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setInputValue(value)
    };
    const getProviderServices = () => {
        getProviderServicesRequest({
            provider: provider.id,
        })
            .then((res) => {
                console.log(res)
                setProviderServices(res.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }




    useEffect(() => {
        getProviderServices()
    }, [])

    const onSearch = (search_value) => {
        console.log('search')
        if (search_value) {
            servicesQuerySetRequest({
                search: search_value,
                of: provider.office_id,
                not_in_pro: provider.id
            })
                .then((res) => {
                    console.log(res)
                    setAllServices(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else setAllServices([])
    }
    const createProviderService = () => {
        createProviderServiceRequest(provider.id, inputValue)
            .then((res) => {
                console.log(res)
                setInputValue()
                setInputVisible(false)
                getProviderServices()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return <>
        <Collapse accordion>
            <Panel header="Assign Services to this Provider" key="1">

                <div
                    style={{
                        marginBottom: 16,
                    }}
                >
                    <TweenOneGroup
                        enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: 'from',
                            duration: 100,
                        }}
                        onEnd={(e) => {
                            if (e.type === 'appear' || e.type === 'enter') {
                                e.target.style = 'display: inline-block';
                            }
                        }}
                        leave={{
                            opacity: 0,
                            width: 0,
                            scale: 0,
                            duration: 200,
                        }}
                        appear={false}
                    >
                        {serviceChild}
                    </TweenOneGroup>
                </div>
                {inputVisible ? (
                    <>
                        <Form
                            onFinish={createProviderService}
                            // ref={modalFormRef}
                        >
                            <Row>
                                <Col>
                                    <Form.Item
                                        className="username"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please provide a name!",
                                            },
                                        ]}
                                        style={{
                                            margin: 0,
                                            marginTop: 10
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            style={{
                                                width: 250,
                                                marginRight: 10
                                            }}
                                            value={inputValue}
                                            placeholder={'Search Service'}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={onSearch}
                                            onChange={handleChange}
                                            notFoundContent={null}
                                            options={(all_services || []).map((service) => ({
                                                value: service.id,
                                                label: service.en_name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label=" " colon={false}>

                                        <Button
                                            type="text"
                                            htmlType={'submit'}
                                            icon={<PlusOutlined />}
                                        >
                                            Add services
                                        </Button>
                                    </Form.Item>
                                </Col>

                            </Row>


                        </Form>
                    </>
                ) : (
                    <Tag onClick={showInput} style={tagPlusStyle}>
                        {loading ?<LoadingOutlined /> : <PlusOutlined />} Add Service
                    </Tag>
                    )}
            </Panel>
        </Collapse>
    </>
}

export default ProviderConfig