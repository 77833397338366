import {Button, Card, Row} from "antd";
import {CheckOutlined} from "@ant-design/icons";

const PricingCard = () => {

    return (
        <>
            <Card
                bordered={false}
                className="header-solid h-full criclebox card-info-2 h-full"
                title={<>
                    <h3>Plan One</h3>
                </>}
                style={{width: "100%", padding: 0, height: "750"}}
                bodyStyle={{padding: "3%",  margin: 0}}
            >
                    <ul style={{padding: 0, margin: 0}} className="list settings-list">
                    <li style={{margin: 0, padding: 0}}>
                        <p style={{padding: 0, margin: 0}}> Description of the plan  and some details of the details of this plandetails of this plan</p>
                        <h1 className="price-charge">
                            $99
                        </h1>
                        <span>/month</span>
                    </li>
                    <li style={{color: 'white'}} color={'white'}>
                        <Row align={'middle'} justify={'center'}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "80%", color: 'yellow' }}
                            >
                                <span className={'pricing-plan-card'} style={{color: 'white', margin: 0}}>Choose Plan</span>
                            </Button>
                        </Row>

                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        2000 Customers / day
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Up to 3 Offices
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Up to 7 licensed POS Apps / office
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Daily Reports
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Daily Reports
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Daily Reports
                                    </span>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'green' }} />
                        <span style={{fontSize: '0.8rem'}}>
                                        Daily Reports
                                    </span>
                    </li>





                </ul>
            </Card>
        </>
    )
}

export default PricingCard