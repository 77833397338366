import axios from 'axios'
import { Config } from './Config'
import {logoutAction} from "../views/Authentications/Authenticator";

function performRequest(method, payload, authenticated = true, isLogoutRequest = false) {
    return new Promise((resolve, reject) => {
        axios
            .post(Config.apiURL + method, payload)
            .then((response) => {
                if ('x-refreshed-token' in response.headers) {
                }
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
                    return
                }
                if (error.response && error.response.status === 429) {
                }
                console.log(error)
                reject(error)
            })
    })
}

export function loginRequest(email, password, rememberMe = false, mfaCode = '') {
    const additional = {}
    if (mfaCode !== '') {
        additional.mfaCode = mfaCode
    }
    return performRequest(
        'api/v1/login',
        {
            username: email,
            password: password,
        },
        false,
    )
}

function performAuthenticatedGetRequest(method, options = {}) {
    let {authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL} = options;

    return new Promise((resolve, reject) => {
        axios
            .get(apiURL + method, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
                    logoutAction()
                    return
                }
                if (error.response && error.response.status === 429) {
                    // if (snackbarRef) {
                    //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
                    // }
                }
                reject(error)
            })
    })
}

function performAuthenticatedPostRequest(
    method,
    payload,
    options = {}
) {
    let {authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL} = options;
    console.log(authenticated)
    return new Promise((resolve, reject) => {
        axios
            .post(apiURL + method, payload, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
                    logoutAction()
                    return
                }
                if (error.response && error.response.status === 429) {
                    // if (snackbarRef) {
                    //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
                    // }
                }
                reject(error)
            })
    })
}

function performAuthenticatedPutRequest(
    method,
    payload,
    authenticated = true,
    isLogoutRequest = false,
) {
    return new Promise((resolve, reject) => {
        axios
            .put(Config.apiURL + method, payload, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
                    // store && logOut(store.dispatch);
                    return
                }
                if (error.response && error.response.status === 429) {
                    // if (snackbarRef) {
                    //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
                    // }
                }
                reject(error)
            })
    })
}

function performAuthenticatedDeleteRequest(method, authenticated = true, isLogoutRequest = false) {
    return new Promise((resolve, reject) => {
        axios
            .delete(Config.apiURL + method, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
                    // store && logOut(store.dispatch);
                    return
                }
                if (error.response && error.response.status === 429) {
                    // if (snackbarRef) {
                    //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
                    // }
                }
                reject(error)
            })
    })
}

export function getRequestUrl(url) {
    return performAuthenticatedGetRequest(url);
}

// ORGANIZATIONS RESOURCES

export function getOrganizationsRequest() {
    return performAuthenticatedGetRequest('api/v1/list_organizations/')
}
export function organizationDetailsRequest(id) {
    return performAuthenticatedGetRequest('api/v1/organization/' + id)
}
export function organizationStatsRequest(id, type='stats') {
    return performAuthenticatedGetRequest('api/v1/organization-stats/' + id + "?type=" + type)
}
export function searchOrganizationsRequest(name) {
    return performAuthenticatedGetRequest('api/v1/list_organizations/?search=' + name)
}
export function searchOffices(name) {
    return performAuthenticatedGetRequest('api/v1/list_offices/?search=' + name)
}
export function createOrganization(values) {
    return performAuthenticatedPostRequest('api/v1/organizations', {
        name: values.name,
        email: values.email,
        phone_no: values.phone_no,
        hq_address: values.hq_address,
        postal_address: values.postal_address,
        district: values.district,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function editOrganization(
    id, name,
    email, phone_no, hq_address, postal_address, district) {
    return performAuthenticatedPutRequest('api/v1/organization/' + id, {
        name: name,
        email: email,
        phone_no: phone_no,
        hq_address: hq_address,
        postal_address: postal_address,
        district: district,
        updated_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

//OFFICES RESOURCES
export function getOfficesRequest(og=null) {
    let  method = 'api/v1/list_offices/'
    if (og !== null) {
        method += `?og=${og}`
    }
    console.log(method)
    return performAuthenticatedGetRequest(method)
}

export function deleteOfficesRequest(id) {
    return performAuthenticatedDeleteRequest('api/v1/office/' + id)
}
export function getOfficesByIdRequest(id) {
    return performAuthenticatedGetRequest('api/v1/office/' + id)
}

export function getOrganizationOfficesRequest(id) {
    return performAuthenticatedGetRequest('api/v1/get_organization_offices/?o=' + id)
}
export function servicesQuerySetRequest({of = '', og = '', search = '', not_in_pro = ''}) {
    const queryParameters = [
        of ? `of=${of}` : '',
        og ? `type=${og}` : '',
        not_in_pro ? `not_in_pro=${not_in_pro}` : '',
        search ? `search=${search}` : '',
    ]
        .filter(Boolean)
        .join('&');

    const method = `api/v1/services_query_set/${queryParameters ? `?${queryParameters}` : ''}`;
    console.log(method)
    return performAuthenticatedGetRequest(method)
}


export function createOfficeRequest(values) {
    return performAuthenticatedPostRequest('api/v1/offices', {
        name: values.name,
        organization: values.organization,
        email: values.email,
        office_phone: values.phone_no,
        physical_address: values.physical_address,
        postal_address: values.postal_address,
        district: values.district,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function editOfficeRequest(
    id, name, organization,
    email, phone_no, physical_address, postal_address, district) {
    return performAuthenticatedPutRequest('api/v1/office/' + id, {
        name: name,
        organization: organization,
        email: email,
        office_phone: phone_no,
        physical_address: physical_address,
        postal_address: postal_address,
        district: district,
        updated_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function createOfficeMarqueeRequest(text, office) {
    return performAuthenticatedPostRequest('api/v1/office-marquees', {
        text: text,
        office: office,
    })
}

export function getMarqueesRequest(of=null) {
    let  method = 'api/v1/list-office-marquees/'
    if (of !== null) {
        method += `?of=${of}`
    }
    return performAuthenticatedGetRequest(method)
}

export function editOfficeMarqueeRequest(id, text, office, status) {
    return performAuthenticatedPutRequest('api/v1/office-marquee/' + id, {
        text: text,
        office: office,
        active: status,
    })
}

// POS RESOURCES

export function getScreensRequest(og=null, of=null) {
    let  method = 'api/v1/apos'
    if (og !== null) {
        method += `?og=${og}`
    }
    else if (of !== null) {
        method += `?of=${of}`
    }
    console.log(method)
    return performAuthenticatedGetRequest(method)
}
//SERVICES RESOURCES

export function getServicesRequest(og=null, of=null) {
    let  method = 'api/v1/list_services/'
    if (og !== null) {
        method += `?og=${og}`
    }
    else if (of !== null) {
        method += `?of=${of}`
    }
    console.log(method)
    return performAuthenticatedGetRequest(method)
}

export function addTicketRequest(service) {
    return performAuthenticatedPostRequest('api/v1/tickets', {
        service: service,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function createServiceRequest(values, office) {
    return performAuthenticatedPostRequest('api/v1/services', {
        en_name: values.en_name,
        sw_name: values.sw_name,
        office: office,
        code: values.code,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function editServiceRequest(id, name, code, office, apos =null) {
    return performAuthenticatedPutRequest('api/v1/service/' + id, {
        name: name,
        code: code,
        office: office,
        apos: apos
    })
}

export function nextServiceCodeRequest(office) {
    return performAuthenticatedPostRequest('api/v1/get-service-code-letter', {
        office: office,
    })
}

//COUNTERS RESOURCES
export function getCountersRequest() {
    return performAuthenticatedGetRequest('api/v1/list_counters/')
}

export function createCounterRequest(name, service) {
    return performAuthenticatedPostRequest('api/v1/counters', {
        name: name,
        service: service,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function getNextOfficeCounterNumberRequest(id) {
    return performAuthenticatedGetRequest('api/v1/counters?next_office_counter_no' + '=' + id)
}

export function countersQuerySetRequest(id, type) {
    return performAuthenticatedGetRequest('api/v1/counters_query_set/?' + type + '=' + id)
}

// PROVIDERS RESOURCES
export function getProvidersRequest(og=null) {
    let  method = 'api/v1/list_providers/'
    if (og !== null) {
        method += `?og=${og}`
    }
    console.log(method)
    return performAuthenticatedGetRequest(method)
}

export function deleteProviderRequest(id) {
    return performAuthenticatedDeleteRequest('api/v1/provider/' + id)
}

export function createProviderRequest(values, office) {
    return performAuthenticatedPostRequest('api/v1/providers', {
        office: office,
        provider_type: values.provider_type,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function getProviderServicesRequest({og='', of = '', provider= ''}) {
    const queryParameters = [
        og ? `og=${og}` : '',
        of ? `of=${of}` : '',
        provider ? `pro=${provider}` : '',
    ]
        .filter(Boolean)
        .join('&');

    const method = `api/v1/list_provider_services/${queryParameters ? `?${queryParameters}` : ''}`;
    return performAuthenticatedGetRequest(method)
}

export function createProviderServiceRequest(provider, service) {
    return performAuthenticatedPostRequest('api/v1/provider-services', {
        service: service,
        provider: provider,
    })
}

export function deleteProviderServiceRequest(provider_id) {
    return performAuthenticatedDeleteRequest(
        'api/v1/provider-service/' + provider_id,
        )
}

export function pullTicketRequest(provider) {
    return performAuthenticatedPostRequest('api/v1/pull-ticket', {
        provider: provider,
    })
}

export function getNextOfficeProviderNumberRequest(id, type) {
    return performAuthenticatedGetRequest(
        'api/v1/providers?next_office_provider_no=' + id +
        '&next_office_provider_type=' + type
    )
}

export function providersQuerySetRequest(id, type) {
    return performAuthenticatedGetRequest('api/v1/providers_query_set/?' + type + '=' + id)
}

export function getRegionsRequest() {
    return performAuthenticatedGetRequest('api/v1/regions')
}

export function getRegionDistrictsRequest(district) {
    return performAuthenticatedGetRequest('api/v1/get_region_districts/?r=' + district)
}

// USERS & OPERATORS CRUD
export function getUsersRequest() {
    return performAuthenticatedGetRequest('api/auth/operators/')
}

export function createOperator(values, office) {
    return performAuthenticatedPostRequest('api/v1/create-operator', {
        username: values.username,
        email: values.email,
        phone_number: values.phone_number,
        office: office,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}
export function editOperator(
    id, name,
    email, phone_no, office, organization) {
    return performAuthenticatedPostRequest('api/v1/create-operator', {
        username: name,
        email: email,
        phone_number: phone_no,
        office: office,
        organization: organization,
        created_by: JSON.parse(localStorage.getItem('user_id'))
    })
}

export function deleteUserRequest(id) {
    return performAuthenticatedDeleteRequest('api/auth/operator/' + id)
}

export function getBodyTypesRequest() {
    return performAuthenticatedGetRequest('api/v1/body-types')
}

export function getBusesRequest() {
    return performAuthenticatedGetRequest('api/v1/buses')
}

// OWNERS RESOURCES
export function getOwnersRequest() {
    return performAuthenticatedGetRequest('api/v1/owners')
}

export function createOwnerRequest(first_name, email, phone_no, tin_number) {
    return performAuthenticatedPostRequest('api/v1/owners', {
        name: first_name,
        phone_no: phone_no,
        tin_number: tin_number,
        email: email,
        created_by: JSON.parse(localStorage.getItem('user_id')),
    })
}

export function editOwnerRequest(id, first_name, email, phone_no, tin_number) {
    return performAuthenticatedPutRequest('api/v1/owner/' + id, {
        name: first_name,
        phone_no: phone_no,
        tin_number: tin_number,
        email: email,
        created_by: JSON.parse(localStorage.getItem('user_id')),
    })
}
//REGIONS RESOURCES

// ADS RESOURCES
export function listAdsRequest(org='') {
    const queryParameters = [
        org ? `organization=${org}` : '',
    ]
        .filter(Boolean)
        .join('&');
    const method = `ads/v1/list-ads${queryParameters ? `?${queryParameters}` : ''}`;

    return performAuthenticatedGetRequest(method, {apiURL: Config.adsServiceURL})
}

export function createAdRequest(data) {
    return performAuthenticatedPostRequest('ads/v1/create-ad', data, {apiURL: Config.adsServiceURL})
}

export function updateAdStatusRequest(id, status) {
    return performAuthenticatedPutRequest('api/v1/ad/' + id, {
        status: status,
        updated_by: JSON.parse(localStorage.getItem('user_id')),
    })
}

// SYSTEM RESOURCES
export function getDashboardDataRequest() {
    return performAuthenticatedGetRequest('api/v1/dashboard-data')
}


