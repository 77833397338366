import {Card, Col, Collapse, Row} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import {editServiceRequest, getScreensRequest, getServicesRequest} from "../../../utils/API";
import { v4 as uuidv4 } from 'uuid';
import {LoadingOutlined} from "@ant-design/icons";

const { Panel } = Collapse;

const ServiceCard = ({apos, services}) => {
    return <>
        <Card
            bordered={false}
            title={apos.name}
            className="header-solid h-full criclebox card-info-2 h-full"
            style={{width: "100%", padding: 0, minHeight: "300px"}}
            bodyStyle={{padding: "3%",  margin: 0}}
        >
            <Droppable droppableId={apos.id.toString()}>
                {(droppableProvided, droppableSnapshot) => (
                    <div
                        ref={droppableProvided.innerRef}
                        style={{minHeight: "250px", width: '100%'}}
                        {...droppableProvided.droppableProps}
                    >
                        {services.map((service, idx) => (
                            <Draggable
                                key={service.id}
                                draggableId={`${service.id}`}
                                index={idx}
                            >
                                {(draggableProvided, draggableSnapshot) => (
                                    <Card
                                        // style={{width: 200, marginBottom: '100px'}}
                                        bodyStyle={{padding: "4%",  margin: 0}}
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                    >
                                        <Row>
                                            <Col span={21}>
                                                {service.en_name} {service.sw_name !== "Null" && " / "  + service.sw_name}

                                            </Col>
                                            {
                                                service.isLoading && <Col span={3}>
                                                    <span><LoadingOutlined style={{color: 'dimgrey'}}/></span>
                                                </Col>
                                            }

                                        </Row>
                                    </Card>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>

        </Card>

    </>
}


const TransferServices = ({office_id}) => {

    const [all_services, setServices] = useState([
        {
            id: 1,
            name: 'Service, 1',
            apos: null
        },
        {
            id: 2,
            name: 'Service, 2',
            apos: {
                id: 1,
                name: 'Screen 1'
            },
        },
        {
            id: 3,
            name: 'Service 3',
            apos: null
        },
    ])
    const [apos_screens, setAposScreens] = useState([
        {
            id: 1,
            name: 'Screen 1'
        },
        {
            id: 2,
            name: 'Screen 2'
        }
    ])
    const null_apos = {
        id: uuidv4().toString(),
        name: 'Null'
    }
    const getScreens = () => {
        getScreensRequest(null, office_id)
            .then((res) => {
                console.log(res)
                setAposScreens(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const getServices = () => {
        getServicesRequest(null, office_id)
            .then((res) => {
                console.log(res)
                res.results.forEach(item => item.isLoading = false)
                setServices(res.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getScreens()
        getServices();
    }, [])

    const updateServiceScreen = (the_service, apos, updatedServices) => {
        editServiceRequest(the_service.id, the_service.name, the_service.code, the_service.office.id, apos != null ? apos.id : null)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                updateIsLoadingService(the_service, false, updatedServices)
            })
    }


    const updateServices = (the_service, apos) => {
        let all_services = updateIsLoadingService(the_service, true)

        const updatedServices = all_services.map(service => {
            if (service.id === the_service.id) {
                return {
                    ...service,
                    apos: apos
                };
            }
            return service;
        });
        setServices(updatedServices);
        updateServiceScreen(the_service, apos, updatedServices)
    }

    const updateIsLoadingService = (the_service, status, services = null) => {
        let custom_services = all_services
        if (services != null) {
            custom_services = services
        }
        const updatedServices = custom_services.map(service => {
            console.log(the_service)
            console.log(service.id === the_service.id)
            if (service.id === the_service.id) {
                return {
                    ...service,
                    isLoading: status
                };
            }
            return service;
        });
        setServices(updatedServices);
        return  updatedServices
    }


    const onDragEnd = result => {
        console.log(result)
        const destination = result.destination
        const source = result.source
        const draggableId = result.draggableId
        // console.log(destination, source)
        //
        // // Check if user tries to drop to unknown destination
        if (!destination) return
        // // If user drags and drops in the same position
        if (destination.droppableId ===  source.droppableId && destination.index === source.index) return;
        // // If user drops within the same column, but in different position
        const sourceAposCol = apos_screens.find(apos => apos.id.toString() === source.droppableId)
        const destinationAposCol = apos_screens.find(apos => apos.id.toString() === destination.droppableId)
        const service = all_services.find(serivice => serivice.id.toString() == draggableId)
        // // if (sourceAposCol.id === destinationAposCol.id) {
        // //     // const newCol = reorderColumnList(
        // //     //     sourceAposCol,
        // //     //     source.index,
        // //     //     destination.index
        // //     // )
        // // }
        //
        console.log(sourceAposCol, destinationAposCol, service)
        if (!destinationAposCol) updateServices(service, null)
        else updateServices(service, destinationAposCol)
        // updateServices(service, destinationAposCol)
        // //
        // console.log(sourceAposCol, destinationAposCol, service)
        // console.log(all_services)

        // If the user moves from one column to another
        // If drop destination == source
    }

    return <>
        <Collapse accordion>
            <Panel header="Assign Service to a Screen / (APOS)" key="1">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row gutter={[32, 16]} className={"mt-25"} justify="center" align="middle">
                        <Col
                            span={6}
                            md={8} sm={12} className="mb-24" style={{maxWidth: '250px', minHeight: '300px'}}
                        >
                            <ServiceCard
                                apos={null_apos}
                                services={all_services.filter((service) => service.apos === null)}
                            />
                        </Col>
                        {apos_screens.map((apos, idx) => (
                            <Col
                                key={idx}
                                span={6}
                                md={8} sm={12} className="mb-24" style={{maxWidth: '250px', minHeight: '300px'}}
                            >
                                <ServiceCard
                                    apos={apos}
                                    services={all_services.filter((
                                        service) =>
                                        service.apos !== null && service.apos.id === apos.id
                                    )}
                                />
                            </Col>
                        ))}
                    </Row>
                </DragDropContext>
            </Panel>
        </Collapse>
    </>
}


export default TransferServices;
