import {PieChartOutlined, SettingOutlined} from "@ant-design/icons";
import OfficeOverview from "./overview";
import {Tabs} from "antd";
import OfficeConfig from "./office_config";

const OfficeTabs = ({office_id}) => {
    const tabs_items = [
        {
            label: (
                <span>
                    <PieChartOutlined /> Statistics
                </span>),
            key: 1,
            children: (<OfficeOverview office_id={office_id} />)
        },
        {
            label: (
                <span>
                    <SettingOutlined /> Configurations
                </span>),
            key: 2,
            children: (<OfficeConfig office_id={office_id}/>)
        },
    ]

    return <>
        <Tabs
            defaultActiveKey="1"
            items={tabs_items}
        />
    </>
}

export default OfficeTabs;
