import {Badge, Button, Card, Col, Dropdown, Menu, message, Popconfirm, Radio, Row, Table} from "antd";

import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {
    deleteProviderRequest,
    getProvidersRequest
} from "../../utils/API";
import {useEffect, useState} from "react";
import Authenticator from "../Authentications/Authenticator";
import {useNavigate, useParams} from "react-router-dom";
import OfficeTabs from "../Offices/components/office_tabs";
import ProviderTabs from "./components/providers_tabs";

const ProvidersList = ({...params}) => {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [providers, setProvidersList] = useState([])
    const [loading, setLoading] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    // const params = useParams()

    const getProvidersList = () => {
        getProvidersRequest(params.og)
            .then((response) => {
                console.log(response.results)
                setProvidersList(response.results)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                message.error("Error Occurred").then()
                // TODO Error handling
            })
    }

    useEffect(() => {
        Authenticator(navigate)
        getProvidersList()
    }, [])

    // Table Columns
    const columns = [
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Organization",
            key: "organization",
            dataIndex: "organization",
        },
        {
            title: "Office",
            key: "office",
            dataIndex: "office",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
        },
    ];

    // Actions
    const renderActions = (provider) => {
        const handleMenuClick = (e) => {
            console.log("Clicked ", e)
        }
        const handleOk = (e) => {
            console.log("Clicked 2", e)
            setConfirmLoading(true)
            deleteProviderRequest(provider.id).then((res) => {
                console.log("Response", res)
                setConfirmLoading(false)
                if (res.status) {
                    getProvidersList()
                    message.success(provider.provider_type + " " + provider.provider_no + " Deleted Successfully", 7).then()
                }
                else {
                    message.error("Error Occurred").then()
                }
            })
                .catch((err) => {
                    console.log(err)
                    message.error("Error Occurred").then()
                })

        }
        const handleCancel = (e) => {
            console.log("Clicked 3", e)
        }
        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="1" icon={<EyeOutlined />}>
                    View
                </Menu.Item>
                <Menu.Item key="2" icon={<EditOutlined />}>
                    Edit
                </Menu.Item>

                <Popconfirm
                    title={"Sure you want to delete " + provider.provider_type + " " + provider.provider_no + "?"}
                    open={open}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                >
                    <Menu.Item key="3" icon={<DeleteOutlined  />}>
                        Delete
                    </Menu.Item>
                </Popconfirm>

            </Menu>
        )
        return (
            <>
                <Dropdown overlay={menu}>
                    <Button>
                        Action <DownOutlined />
                    </Button>
                </Dropdown>
            </>
        )
    }
    const badgeColor = (status) => {
        if (status){
            return "green"
        }
        else {
            return "red"
        }
    }
    // Constructing Data
    let data = providers.map((provider, idx) => {
        return {
            key: provider.id,
            office_id: provider.office.id,
            created_at: (
                <>
                    <div className="text-sm">{provider.created_at}</div>
                </>
            ),
            name: (

                <>
                    <Badge dot color={badgeColor(provider.online_status)}>
                        <div className="bold"> {provider.provider_type +' '+ provider.provider_no} </div>
                    </Badge>
                </>
            ),
            organization: (
                <>
                    <div className="bold"> {provider.office.organization.name} </div>
                </>
            ),
            office: (
                <>
                    <div className="bold"> {provider.office.name} </div>
                </>
            ),
            actions: renderActions(provider)

        }
    })

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Active Providers"
                            extra={
                                <>
                                    <Button
                                        type="primary"
                                        onClick={() => {navigate('/create-provider', {replace: true})}}
                                        style={{marginRight: 200}}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Provider
                                    </Button>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ACTIVE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    loading={loading}
                                    className="ant-border-space"
                                    expandable={params.og !== undefined && {
                                        expandedRowRender: (record) => {
                                            console.log(record)
                                            return <ProviderTabs provider={record}/>
                                        },
                                    }}
                                />
                            </div>
                        </Card>

                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ProvidersList