import {PieChartOutlined, SettingOutlined} from "@ant-design/icons";
import {Tabs} from "antd";
import ProviderConfig from "./provider_config";

const ProviderTabs = ({provider}) => {
    const tabs_items = [
        {
            label: (
                <span>
                    <PieChartOutlined /> Statistics
                </span>),
            key: 1,
            // children: (<OfficeOverview office_id={office_id} />)
            children: <> Hello</>
        },
        {
            label: (
                <span>
                    <SettingOutlined /> Configurations
                </span>),
            key: 2,
            children: (<ProviderConfig provider={{id: provider.key, office_id: provider.office_id}}/>)
        },
    ]

    return <>
        <Tabs
            defaultActiveKey="1"
            items={tabs_items}
        />
    </>
}

export default ProviderTabs;
