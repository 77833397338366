import {Navigation, useLocation, useNavigate} from "react-router-dom";

const Authenticator = (navigate) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user != null) {
        return 0
    }

    else {
        if (navigate !== undefined) {
            navigate('/sign-in', {replace: true})
        }
        return  1
    }
}
export const logoutAction = () => {
    localStorage.clear()
    window.location.replace('/sign-in')
}
export default Authenticator