import {Row} from "antd";
import {useEffect, useState} from "react";
import TransferServices from "./transfer_services";
import OfficeMarquee from "./office_marques";


const OfficeConfig = ({office_id}) => {

    useEffect(() => {
        // getScreens()
        // getServices();
    }, [])



    return <>
        <TransferServices office_id={office_id}/>
        <OfficeMarquee office_id={office_id} />
    </>
}


export default OfficeConfig;
