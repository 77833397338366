import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Config } from '../utils/Config';
const { Dragger } = Upload;

const FilesUploader = ({ onResponse, multiple = false, name = 'video', apiURL = Config.apiURL, method = 'api/v1/upload-file' }) => {
    const props = {
        name: name,
        multiple: multiple,
        action: apiURL + method,
        onChange(info) {
            console.log(info);
            const { status, response } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                onResponse(response);
                message.success(`${info.file.name} file uploaded successfully.`).then();
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`).then();
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint" style={{ padding: 5 }}>
                Supports single or bulk upload. Strictly prohibited from uploading unethical or banned files.
            </p>
        </Dragger>
    );
};
export default FilesUploader;
