/*!
=========================================================
* FoleniQMS Web App - v1.0.0
=========================================================
*/
import './App.css';
import {BrowserRouter as Router, Route, Navigate, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import Main from "./components/layout/Main";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Login from "./views/Authentications/Login";
import OrganizationsList from "./views/Organizations/OrganizationsList";
import OfficesList from "./views/Offices/OfficesList";
import ServicesList from "./views/Services/Services";
import ProvidersList from "./views/Providers/Providers";
import CreateOrganization from "./views/Organizations/createOrganization";
import CreateOffice from "./views/Offices/createOffice";
import CreateService from "./views/Services/createService";
import CreateProvider from "./views/Providers/createProvider";
import UsersList from "./views/Users/UsersList";
import CreateUser from "./views/Users/createUser";
import OrganizationProfile from "./views/Organizations/organizationProfile";
import CreateAd from "./views/Organizations/components/createAd";

function App() {
  return (
    <div className="App">
      <Router>
        {/*<Route path="/sign-up" exact component={SignUp} />*/}
        <Routes>
          <Route path="/sign-in" exact element={<Login />} />
          <Route element={<Main />}>
            <Route path="/dashboard" element={<Home />} />

            {/*Organizations Paths*/}
            <Route path="/organization/:id" element={<OrganizationProfile />} />
            <Route path="/add-ad/:organization_id" element={<CreateAd />} />
            <Route path="/organizations" element={<OrganizationsList />} />
            <Route path="/create-organization" element={<CreateOrganization />} />

            {/*Offices Paths*/}
            <Route path="/offices" element={<OfficesList />} />
            <Route path="/create-office" element={<CreateOffice />} />

            {/*Services Paths*/}
            <Route path="/services" element={<ServicesList />} />
            <Route path="/create-service" element={<CreateService />} />

            {/*Providers Paths*/}
            <Route path="/providers" element={<ProvidersList />} />
            <Route path="/create-provider" element={<CreateProvider />} />

            {/*Users Paths*/}
            <Route path="/users" element={<UsersList />} />
            <Route path="/create-user" element={<CreateUser />} />

            <Route path="/tables" element={<Tables />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/rtl" element={<Rtl />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
