import {
    getOrganizationOfficesRequest,
    getRegionDistrictsRequest,
    getRegionsRequest, getRequestUrl, searchOffices,
    searchOrganizationsRequest, servicesQuerySetRequest
} from "./API";

export const loadRegions = (setRegions) => {
    getRegionsRequest()
        .then((response) => {
            console.log(response)
            setRegions(response)
        })
}
export const loadDistricts = (region, setDistricts) => {
    getRegionDistrictsRequest(region)
        .then((response) => {
            console.log(response)
            setDistricts(response)
        })
}

export const loadOrganizations = (inputValue, callback) => {
    searchOrganizationsRequest(inputValue).then((response) => {
        console.log(response)
        const re = response.results.map((data) => {
            console.log(data)
            return {
                value: data.id,
                label: data.name,
            }
        })
        console.log(re)
        callback(re)
    })
}

export const loadOffices = (inputValue, callback) => {
    searchOffices(inputValue).then((response) => {
        console.log(response)
        const re = response.results.map((data) => {
            console.log(data)
            return {
                value: data.id,
                label: data.name,
            }
        })
        console.log(re)
        callback(re)
    })
}

export const getOrganizationServiceList = (id) => {
    servicesQuerySetRequest({og: id})
        .then((response) => {
            console.log(response)
            // setServices(response)
            // getOrganizationOfficeList(id)
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getOrganizationOfficeList = (id, setOffices) => {
    getOrganizationOfficesRequest(id)
        .then((response) => {
            console.log(response)
            setOffices(response)
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getOfficeServiceList = (id, setServices) => {
    servicesQuerySetRequest({of: id})
        .then((response) => {
            setServices(response)
        })
}

// PAGINATION FUNCTIONS
export const performGetUrl = (toPage, setData, setPrevious, setNext) => {
    getRequestUrl(toPage).then((response) =>{
        const usersResponse = response.results
        if (usersResponse.length > 0){
            setData(response.results)
            setPrevious(response.previous);
            setNext(response.next);
            // setIsLoading(false);
        }else{
            // enqueueSnackbar(t('common.noRecord'), {variant: 'info'})
        }
    }) .catch(() => {
        // enqueueSnackbar(t('common.connectivityIssue'), {variant: 'error'})
    });;
}

export const prevPage = (previous, setData, setPrevious, setNext) => {
    // setIsLoading(true);
    if (previous !== null) {
        let toPage = previous.replace("http://127.0.0.1:7700/", "");
        performGetUrl(toPage, setData, setPrevious, setNext);
    }
}

export const nextPage = (next, setData, setPrevious, setNext) => {
    // setIsLoading(true);
    console.log(next)
    if (next !== null) {
        console.log(next)
        let toPage = next.replace("http://127.0.0.1:7700/", "");
        console.log(toPage)
        performGetUrl(toPage, setData, setPrevious, setNext);
    }
}

export const blinkAsyncInputs = (setClassName) => {

}
