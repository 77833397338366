import {Button, Card, Col, Dropdown, Menu, message, Radio, Row, Table, Tag, Upload} from "antd";

import {
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    HomeOutlined, PlusOutlined,
} from "@ant-design/icons";
import {getUsersRequest} from "../../utils/API";
import {useEffect, useState} from "react";
import Authenticator from "../Authentications/Authenticator";
import {useNavigate} from "react-router-dom";

const UsersList = () => {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [users, setUsersList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const getUsersList = () => {
        setLoading(true)
        getUsersRequest()
            .then((response) => {
                console.log(response.results)
                setUsersList(response.results)
                // setPrevious(response.previous);
                // setNext(response.next);
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                message.error("Error Occurred").then()
                console.log(error)
            })
    }

    useEffect(() => {
        Authenticator(navigate)
        // getDashboardData().then();
        getUsersList()
    }, [])

    // Table Columns
    const columns = [
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "Phone Number",
            key: "phone_no",
            dataIndex: "phone_no",
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Office",
            key: "office",
            dataIndex: "office",
        },
        {
            title: "Role",
            key: "role",
            dataIndex: "role",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
        },
    ];

    // Actions
    const renderActions = () => {
        const handleMenuClick = (e) => {
            console.log(e)
        }
        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="1" icon={<EyeOutlined />}>
                    View
                </Menu.Item>
                <Menu.Item key="2" icon={<EditOutlined />}>
                    Edit
                </Menu.Item>
                <Menu.Item key="3" icon={<HomeOutlined />}>
                    Offices
                </Menu.Item>
            </Menu>
        )
        return (
            <>
                <Dropdown overlay={menu}>
                    <Button>
                        Action <DownOutlined />
                    </Button>
                </Dropdown>
            </>
        )
    }

    // Constructing Data
    let data = users.map((user, idx) => {
        return {
            created_at: (
                <>
                    <div className="text-sm">{user.date_joined}</div>
                </>
            ),
            name: (
                <>
                    <div className="bold"> {user.username} </div>
                </>
            ),
            phone_no: (
                <>
                    <div className="bold"> {user.phone_number} </div>
                </>
            ),
            office: (
                <>
                    <div>{user.office != null ? user.office.name : 'Null' } </div>
                </>
            ),
            role: (
                <>
                    <Tag color="green">Admin</Tag>
                </>
            ),
            email: (
                <>
                    <div className="bold"> {user.email} </div>
                </>
            ),
            actions: renderActions()

        }
    })

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Active Users"
                            extra={
                                <>

                                    <Button
                                        type="primary"
                                        onClick={() => {navigate('/create-user', {replace: true})}}
                                        style={{marginRight: 200}}
                                        icon={<PlusOutlined />}
                                    >
                                        Add User
                                    </Button>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ACTIVE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    className="ant-border-space"
                                    loading={loading}
                                />

                            </div>
                        </Card>

                    </Col>
                </Row>
            </div>
        </>
    );
}

export default UsersList