import {Card, Col, Row, Typography} from "antd";
import VisitChart from "./chart";
import {organizationStatsRequest} from "../../../utils/API";
import project1 from "../../../assets/images/home-decor-1.jpeg";
import project2 from "../../../assets/images/home-decor-2.jpeg";
import project3 from "../../../assets/images/home-decor-3.jpeg";
import {dollor, heart, profile} from "../../../utils/svgs";
import {useEffect, useState} from "react";

const Statistics = ({organization_id}) => {
    const { Title, Text } = Typography;
    const [, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState(false);
    const [stats, setStats] = useState({
        today_customers: 0,
        total_customers: 0,
        offices: 0,
        providers: 0,
    })

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const getOrganizationStats = () => {
        organizationStatsRequest(organization_id)
            .then((res) => {
                console.log(res)
                setStats(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getOrganizationStats()
    }, [])


    const items = [
        {
            key: '1',
            label: `Tab 1`,
            children: `Content of Tab Pane 1`,
        },
        {
            key: '2',
            label: `Tab 2`,
            children: `Content of Tab Pane 2`,
        },
        {
            key: '3',
            label: `Tab 3`,
            children: `Content of Tab Pane 3`,
        },
    ];


    const project = [
        {
            img: project1,
            titlesub: "Project #1",
            title: "Modern",
            disciption:
                "As Uber works through a huge amount of internal management turmoil.",
        },
        {
            img: project2,
            titlesub: "Project #2",
            title: "Scandinavian",
            disciption:
                "Music is something that every person has his or her own specific opinion about.",
        },
        {
            img: project3,
            titlesub: "Project #3",
            title: "Minimalist",
            disciption:
                "Different people have different taste, and various types of music, Zimbali Resort",
        },
    ];


    const count = [
        {
            today: "Today’s Visits",
            title: (parseInt(stats.today_customers)).toLocaleString(),
            persent: "",
            icon: dollor,
            bnb: "bnb2",
        },
        {
            today: "Total Visits",
            title: (parseInt(stats.total_customers)).toLocaleString(),
            persent: "",
            icon: dollor,
            bnb: "bnb2",
        },
        {
            today: "Offices / Branches",
            title: (parseInt(stats.offices)).toLocaleString(),
            persent: "",
            icon: profile,
            bnb: "bnb2",
        },
        {
            today: "Total Providers",
            title: (parseInt(stats.providers)).toLocaleString(),
            persent: "",
            icon: heart,
            bnb: "redtext",
        },
    ];

    return (
        <>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
            {count.map((c, index) => (
                <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mb-24"
                >
                    <Card bordered={false} className="criclebox ">
                        <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span>{c.today}</span>
                                    <Title level={3}>
                                        {c.title} <small className={c.bnb}>{c.persent}</small>
                                    </Title>
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box">{c.icon}</div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
            <VisitChart organization_id={organization_id} />
        </>
    )
}

export default Statistics;