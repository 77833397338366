
import {useEffect, useState} from "react";

import {
    Row,
    Col,
    Card,
    Avatar,
    Radio,
} from "antd";

import {
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatar from "../../assets/images/gnm_logo.png";
import OrganizationOverView from "./components/overview";
import OfficesList from "../Offices/OfficesList";
import {OrganizationProfileTabs} from "../../utils/constants";
import ServicesList from "../Services/Services";
import ProvidersList from "../Providers/Providers";
import Authenticator from "../Authentications/Authenticator";
import {useNavigate, useParams} from "react-router-dom";
import {organizationDetailsRequest} from "../../utils/API";

function OrganizationProfile() {
    const [tab, setTab] = useState(OrganizationProfileTabs.OVERVIEW)
    const [org_data, setOrgData] = useState(null)
    const navigate = useNavigate()
    const {id} = useParams();

    const getOrganizationDetails = () => {
        organizationDetailsRequest(id)
            .then((res) => {
                console.log(res)
                setOrgData(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        Authenticator(navigate)
        getOrganizationDetails()
    }, [])

    return (
        <>
            <div
                className="profile-nav-bg"
                style={{ backgroundImage: "url(" + BgProfile + ")" }}
            ></div>

            <Card
                className="card-profile-head"
                bodyStyle={{ display: "none" }}
                title={
                    <Row justify="space-between" align="middle" gutter={[24, 0]}>
                        <Col span={24} md={12} className="col-info">
                            <Avatar.Group>
                                <Avatar size={74} shape="square" src={profilavatar} />

                                <div className="avatar-info">
                                    <h4 className="font-semibold m-0">{org_data !== null && org_data.name}</h4>
                                    <p>Joined at {org_data == null ? "" : org_data.created_at.substr(0, org_data.created_at.indexOf('T'))}</p>
                                </div>
                            </Avatar.Group>
                        </Col>
                        <Col
                            span={24}
                            md={12}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Radio.Group
                                defaultValue={OrganizationProfileTabs.OVERVIEW}
                                onChange={(e) => {
                                    setTab(e.target.value)
                                }}
                            >
                                <Radio.Button value={OrganizationProfileTabs.OVERVIEW}>OVERVIEW</Radio.Button>
                                <Radio.Button value={OrganizationProfileTabs.OFFICES}>OFFICES</Radio.Button>
                                <Radio.Button value={OrganizationProfileTabs.SERVICES}>SERVICES</Radio.Button>
                                <Radio.Button value={OrganizationProfileTabs.PROVIDERS}>PROVIDERS</Radio.Button>
                                <Radio.Button value={OrganizationProfileTabs.USERS}>USERS</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                }
            ></Card>
            {tab === OrganizationProfileTabs.OVERVIEW && <OrganizationOverView organization_id={id}/>}
            {tab === OrganizationProfileTabs.OFFICES && <OfficesList og={id}/>}
            {tab === OrganizationProfileTabs.SERVICES && <ServicesList og={id}/>}
            {tab === OrganizationProfileTabs.PROVIDERS && <ProvidersList og={id}/>}

        </>
    );
}

export default OrganizationProfile;
