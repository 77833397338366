export const OrganizationProfileTabs = {
    OVERVIEW: "0",
    OFFICES: "1",
    SERVICES: "2",
    PROVIDERS: "3",
    USERS: "4",
};

export const PROVIDER_TYPES = {
    COUNTER:"Counter",
    DIRISHA: "Dirisha",
    DAKTARI: "Daktari",
    // MAABARA: "Maabara",
    CHUMBA: "Chumba",
    MEZA: "Meza",
    FLOOR: "Floor",
    MENEJA: "Meneja"
};

