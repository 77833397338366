import {Button, Card, Col, Dropdown, Menu, message, Popconfirm, Radio, Row, Table} from "antd";

import {
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import {deleteOfficesRequest, getOfficesRequest} from "../../utils/API";
import {useEffect, useState} from "react";
import Authenticator from "../Authentications/Authenticator";
import {useNavigate} from "react-router-dom";
import OfficeOverview from "./components/overview";
import OfficeTabs from "./components/office_tabs";

const OfficesList = ({...params}) => {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [offices, setOfficesList] = useState([])
    const [loading, setLoading] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const getOfficesList = () => {
        getOfficesRequest(params.og)
            .then((response) => {
                console.log(response.results)
                setOfficesList(response.results)

                setLoading(false)
                // setPrevious(response.previous);
                // setNext(response.next);
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                message.error("Error Occurred")
            })
    }

    useEffect(() => {
        Authenticator(navigate)
        console.log(params)
        getOfficesList()
    }, [])

    // Table Columns
    const columns = [
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Organization",
            key: "organization",
            dataIndex: "organization",
        },
        {
            title: "Physical Address",
            key: "address",
            dataIndex: "address",
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
        },
    ];

    // Actions
    const renderActions = (office) => {
        const handleMenuClick = (e) => {
            console.log("Clicked ", e)
        }
        const handleOk = (e) => {
            console.log("Clicked 2", e)
            setConfirmLoading(true)
            deleteOfficesRequest(office.id).then((res) => {
                setConfirmLoading(false)
                if (res.status) {
                    getOfficesList()
                    message.success(office.name + " Deleted Successfully", 7).then()
                }
                else {
                    message.error("Error Occurred").then()
                }
            })
                .catch((err) => {
                    message.error("Error Occurred").then()
                })

        }
        const handleCancel = (e) => {
            console.log("Clicked 3", e)
        }
        const handleCopyClick = () => {
            navigator.clipboard.writeText(office.license_key.toString()).then()
        }
        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="1" icon={<EyeOutlined />}>
                    View
                </Menu.Item>
                <Menu.Item key="2" icon={<EditOutlined />}>
                    Edit
                </Menu.Item>

                <Popconfirm
                    title={"Sure you want to delete " + office.name + "?"}
                    open={open}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                >
                    <Menu.Item key="3" icon={<DeleteOutlined  />}>
                        Delete
                    </Menu.Item>
                </Popconfirm>
                <Menu.Item key="2" icon={<EditOutlined />} onClick={handleCopyClick}>
                    CL
                </Menu.Item>
            </Menu>
        )
        return (
            <>
                <Dropdown overlay={menu}>
                    <Button>
                        Action <DownOutlined />
                    </Button>
                </Dropdown>
            </>
        )
    }

    // Constructing Data
    let data = offices.map((office, idx) => {
        return {
            key: office.id,
            created_at: (
                <>
                    <div className="text-sm">{office.created_at}</div>
                </>
            ),
            name: (
                <>
                    <div className="bold"> {office.name} </div>
                </>
            ),
            organization: (
                <>
                    <div className="bold"> {office.organization.name} </div>
                </>
            ),
            address: (
                <>
                    <div className="bold"> {office.physical_address} </div>
                </>
            ),
            email: (
                <>
                    <div className="bold"> {office.email} </div>
                </>
            ),
            actions: renderActions(office)

        }
    })

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Active Offices"
                            extra={
                                <>
                                    <Button
                                        type="primary"
                                        onClick={() => {navigate('/create-office', {replace: true})}}
                                        style={{marginRight: 200}}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Office
                                    </Button>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ACTIVE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    loading={loading}
                                    expandable={params.og !== undefined && {
                                        expandedRowRender: (record) => {
                                            console.log(record)
                                            return <OfficeTabs office_id={record.key}/>
                                        },
                                    }}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>

                    </Col>
                </Row>
            </div>
        </>
    );
}

export default OfficesList