import {Button, Col, Collapse, Modal, Row, Switch, Table, Typography} from "antd";
import {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import Authenticator from "../../Authentications/Authenticator";
import {createOfficeMarqueeRequest, editOfficeMarqueeRequest, getMarqueesRequest} from "../../../utils/API";

const { Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;
const text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

const Switcher = ({marquee, editMarquee}) => {
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Switch
                loading={loading}
                defaultChecked={marquee.active}
                onChange={(e) => {
                    // Do action
                    console.log(e)
                    editMarquee(marquee.id, marquee.text, e)
                }}
            />
        </>
    )
}

const MarqueeTextModel = ({marquee = null, modal_open, setModalOpen, setRowMarquee, createMarquee, editMarquee}) => {
    const [_text, setText] = useState(marquee != null ? marquee.text : '')
    const [editing, setEditing] = useState(marquee != null)
    const [loading, setLoading] = useState(false)
    console.log('Herererer', marquee)
    console.log('Herererer', _text)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const title =`${marquee != null ? 'Edit': 'Add'} marquee text`


    const handleSave = (close=true) => {
        if (marquee == null && !close) {
            createMarquee(_text)
        }
        else {
            editMarquee(marquee.id, _text)
        }
        setRowMarquee(null)
        setModalOpen(false)
    }
    const check = () => {
        // setText('Heloo')
        setText(marquee !== null ? marquee.text : '')
        setEditing(marquee === null)
    }
    useEffect(() => {
        check()
    }, [modal_open])
    return (
        <>
            <Modal
                title={title}
                centered
                open={modal_open}
                width={700}
                onCancel={() => handleSave(true)}
                footer={[
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => handleSave(false)}
                    >
                        Save Changes
                    </Button>,
                ]}

            >
                <Paragraph
                    editable={{
                        editing: editing,
                        tooltip: 'Click to edit text',
                        onStart: () => setEditing(prevState => !prevState),
                        onChange: (e) => {setText(e); setEditing(false)},
                        triggerType: 'icon',
                    }}
                >
                    {_text}
                </Paragraph>
            </Modal>

        </>
    )
}


const OfficeMarquee = ({office_id}) => {
    const [marquees, setMarquees] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowMarquee, setRowMarquee] = useState(null)
    const [modal_open, setModalOpen] = useState(false)

    useEffect(() => {
        getMarquees()
    }, [])

    const createMarquee = (text) => {
        createOfficeMarqueeRequest(text, office_id)
            .then((res) => {
                console.log(res)
                getMarquees()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const editMarquee = (id, text, status=false) => {
        editOfficeMarqueeRequest(id, text, office_id, status)
            .then((res) => {
                console.log(res)
                getMarquees()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getMarquees = () => {
        getMarqueesRequest(office_id)
            .then((res) => {
                console.log(res)
                setMarquees(res.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const columns = [
        {
            title: "Marquee text",
            dataIndex: "text",
            key: "text",
            width: '80%'
        },
        {
            title: "Active",
            dataIndex: "actions",
            key: "actions",
        },
    ];

    let data = marquees.map((marquee, idx) => {
        return {
            key: marquee.id,
            text: (
                <>
                    <Text
                        ellipsis={true}
                        style={{maxWidth: '500px'}}
                    >
                        {marquee.text}
                    </Text>
                    <span style={{marginLeft: 6}}>
                        <Link onClick={(e) => {
                            // e.preventDefault()
                            setModalOpen(true)
                            setRowMarquee(marquee)
                        }}>
                          Read more
                        </Link>
                    </span>
                </>
            ),
            actions: (
                <>
                    <Switcher marquee={marquee} editMarquee={editMarquee} />
                </>
            )

        }
    })

    return <>
        <MarqueeTextModel
            marquee={rowMarquee}
            setRowMarquee={setRowMarquee}
            modal_open={modal_open}
            setModalOpen={setModalOpen}
            createMarquee={createMarquee}
            editMarquee={editMarquee}
        />
        <Collapse accordion>
            <Panel header="Manage Office Marquees" key="1">
                <Row justify={'end'}>
                    <Button
                        type="primary"
                        onClick={() => {setModalOpen(true)}}
                        style={{marginRight: 50}}
                        icon={<PlusOutlined />}
                    >
                        Add Marquee
                    </Button>
                </Row>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    className="ant-border-space"
                />
            </Panel>
        </Collapse>
    </>
}


export default OfficeMarquee;
