import {Button, Card, Col, Form, Input, message, Row} from "antd";
import {createAdRequest, organizationDetailsRequest} from "../../../utils/API";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import FilesUploader from "../../../components/FileUploader";
import {Config} from "../../../utils/Config";
import TextArea from "antd/es/input/TextArea";

const CreateAd = () => {
    const [loading, setLoading] = useState(false);
    const [save_name, setSaveName] = useState(null);
    const [file_name, setFileName] = useState(null);
    const [organization, setOrganization] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    const getOrganizationDetail = () => {
        organizationDetailsRequest(params.organization_id)
            .then((res) => {
                setOrganization(res)
            })
            .catch((err) => {
                console.log(err)
                message.error('Failed to load organization details').then()
                navigate('/organizations', {replace: true})
            })
    }

    const onFinish = (values) => {
        const payload = {
            title: values.title,
            description: values.description,
            save_name: save_name,
            file_name: file_name,
            organization_id: params.organization_id,
            created_by_id: JSON.parse(localStorage.getItem('user_id'))
        }
        setLoading(true)
        createAdRequest(payload)
            .then((res) => {
                console.log(res)
                setLoading(false)
                message.success(`Ad ${file_name} successfully created`)
                navigate('/organization/' + params.organization_id, { replace: true })
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                message.error('Something went wrong')
            })
    }

    const onFinishFailed = () => {}

    useEffect(() => {
        getOrganizationDetail()
    }, [])

    return (
        <>
            <Card style={{ paddingBottom: 20 }}>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"

                >
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                className="username"
                                label="Ad Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the Ad's title!",
                                    },
                                ]}
                            >
                                <Input placeholder="Ad title..." type="text"/>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                className="username"
                                label="Ad Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the Ad's description!",
                                    },
                                ]}
                            >
                                <TextArea rows={4} placeholder="Some description of this ad..." />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <FilesUploader
                                onResponse={(res) => {
                                    setSaveName(res.save_name)
                                    setFileName(res.file_name)
                                }}
                                apiURL={Config.adsServiceURL}
                                method={'ads/v1/upload-ad-video'}
                                multiple={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Form.Item label=" " colon={false}>
                            <Button type="primary" loading={loading} htmlType="submit" style={{ width: "200px" }}>
                                Submit Data
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default CreateAd;
