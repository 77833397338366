export const monthRange = () => {
    const today = new Date(); // Get today's date
    const months = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthRange = []; // Initialize an empty array to store the month names
    for (let m = 7; m >= 0; m--) {
        const d = new Date(today.getFullYear(), today.getMonth() - m, 1); // Get the date for the month m months ago
        monthRange.push(months[d.getMonth()]); // Add the name of the month to the monthRange array
    }
    return monthRange
}