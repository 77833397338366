import {Button, Card, Col, Dropdown, Menu, message, Radio, Row, Skeleton, Space, Table, Upload} from "antd";

import {
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    HomeOutlined, PlusOutlined,
    SettingOutlined,
    ToTopOutlined,
    UserOutlined
} from "@ant-design/icons";
import {getOrganizationsRequest} from "../../utils/API";
import {useEffect, useState} from "react";
import Title from "antd/es/typography/Title";
import SubMenu from "antd/es/menu/SubMenu";
import Authenticator from "../Authentications/Authenticator";
import {useNavigate} from "react-router-dom";

const OrganizationsList = () => {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [organizations, setOrganizationsList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const getOrganizationsList = () => {
        setLoading(true)
        getOrganizationsRequest()
            .then((response) => {
                console.log(response.results)
                setOrganizationsList(response.results)
                // setPrevious(response.previous);
                // setNext(response.next);
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                message.error("Error Occurred").then()
                console.log(error)
            })
    }

    useEffect(() => {
        Authenticator(navigate)
        // getDashboardData().then();
        getOrganizationsList()
    }, [])

    // Table Columns
    const columns = [
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "HQ Address",
            key: "address",
            dataIndex: "address",
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
        },
    ];

    // Actions
    const renderActions = (organization) => {
        const handleMenuClick = (e) => {
            console.log(e)
            navigate(e.key)
        }
        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key={"/organization/"+organization.id} icon={<EyeOutlined />}>
                    View
                </Menu.Item>
                <Menu.Item key={"/organization/"+organization.id} icon={<EditOutlined />}>
                    Edit
                </Menu.Item>
            </Menu>
        )
        return (
            <>
                <Dropdown overlay={menu}>
                    <Button>
                        Action <DownOutlined />
                    </Button>
                </Dropdown>
            </>
        )
    }

    // Constructing Data
    let data = organizations.map((organization, idx) => {
        return {
            created_at: (
                <>
                    <div className="text-sm">{organization.created_at}</div>
                </>
            ),
            name: (
                <>
                    <div className="bold"> {organization.name} </div>
                </>
            ),
            address: (
                <>
                    <div className="bold"> {organization.hq_address} </div>
                </>
            ),
            email: (
                <>
                    <div className="bold"> {organization.email} </div>
                </>
            ),
            actions: renderActions(organization)

        }
    })

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Active Organizations"
                            extra={
                                <>

                                    <Button
                                        type="primary"
                                        onClick={() => {navigate('/create-organization', {replace: true})}}
                                        style={{marginRight: 200}}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Organization
                                    </Button>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ACTIVE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    className="ant-border-space"
                                    loading={loading}
                                />

                            </div>
                        </Card>

                    </Col>
                </Row>
            </div>
        </>
    );
}

export default OrganizationsList