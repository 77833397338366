import {Card, Col, FloatButton, Image, Row} from "antd";
import Meta from "antd/es/card/Meta";
import {EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {listAdsRequest} from "../../../utils/API";
import {useEffect, useState} from "react";

const AdsManager = ({ organization_id }) => {
    const [ads, setAds] = useState([])

    const navigate = useNavigate()

    const getAds = () => {
        listAdsRequest(organization_id)
            .then((res) => {
                console.log(res)
                setAds(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAds()
    }, [])

    const Ads = () => {
        return ads.map((item, id) => (
            <Col md={6} className="col-info">
                <Card
                    style={{ width: 250 }}
                    cover={
                        <Image
                            alt="example"
                            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                    }
                    // extra={<EllipsisOutlined key="ellipsis" />}
                    actions={[
                        // <SettingOutlined key="setting" />,
                        // <EditOutlined key="edit" />,
                        <EllipsisOutlined key="ellipsis" />,
                    ]}
                >
                    <Meta
                        // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                        title={item.title}
                        description={item.description}
                    />
                </Card>
            </Col>
        ))
    }

    return (
        <>
            <Row justify="start" align="middle" gutter={[24, 12]}>
                <Ads />
            </Row>
            <FloatButton icon={<PlusOutlined />} onClick={() => navigate('/add-ad/' + organization_id.toString(), {replace: true})} />
        </>
    )
}

export default AdsManager;
